import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import { Container } from "../components/global.js"

const Success = props => (
  <Layout>
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page" />
    </Helmet>
    <HeaderWrapper>
      <Container>
        <Flex>
          <div id="main" className="alt">
            <section id="one">
              <div className="inner">
                <header className="major">
                  <h1>Thank You</h1>
                </header>

                <p>You are in! We will let you know as soon as its out.</p>
                <Link to="/" className="link">
                  Back
                </Link>
              </div>
            </section>
          </div>
        </Flex>
      </Container>
    </HeaderWrapper>
    <FooterWrapper id="footer">
      © FinRin.com 2020, All Rights Reserved.
    </FooterWrapper>
  </Layout>
)

export default Success

const HeaderWrapper = styled.header`
  background-color: #fff;
  padding: 120px 0 80px 0;
  align-content: center;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const Flex = styled.div`
  display: grid;
  align-content: center;
  }
`
const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.background.white};
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 4vh;
  padding-bottom: 4vh;
`
